import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

export enum FeatureFlag {
  DisablePriceEditing = 'disablePriceEditing',
  DiscontinueItem = 'itemManagementServiceDiscontinueItem',
  RequireInventoryItemDimensionFields = 'imsRequireInventoryItemDimensionFields',
  BrandedItems = 'imsBrandedItems',
  CustomLocationItems = 'item-management-service-40856-add-location-items',
  RichTextEditorForItemDescription = 'richTextEditorForItemDescription',
  EditableItemTags = 'imsEditableItemTags',
  canUpdatePerishability = 'canUpdatePerishability',
  itemManagementServiceBrandAssociationEnabled = 'itemManagementServiceBrandAssociationEnabled',
}

type FlagDefaultType = {
  [key in FeatureFlag]: boolean;
};

const FLAG_DEFAULTS: FlagDefaultType = {
  disablePriceEditing: true,
  itemManagementServiceDiscontinueItem: true,
  imsRequireInventoryItemDimensionFields: false,
  imsBrandedItems: true,
  itemManagementServiceBrandAssociationEnabled: true,
  'item-management-service-40856-add-location-items': true,
  richTextEditorForItemDescription: false,
  imsEditableItemTags: false,
  canUpdatePerishability: false,
};

export const useFeatureFlipper = (flag: FeatureFlag) => {
  const [isEnabled, setIsEnabled] = useState<boolean | undefined>(undefined);
  const allFlags = useFlags();

  useEffect(() => {
    const isFlagEnabled: boolean = allFlags[flag] ?? FLAG_DEFAULTS[flag];
    setIsEnabled(isFlagEnabled);
  });

  return isEnabled;
};

export const useIsPriceEditingDisabled = (): boolean => {
  return useFeatureFlipper(FeatureFlag.DisablePriceEditing) ?? true;
};

export const useIsItemBrandAssociationEnabled = (): boolean => {
  return (
    useFeatureFlipper(
      FeatureFlag.itemManagementServiceBrandAssociationEnabled,
    ) ?? false
  );
};

export const useCanUpdatePerishability = (): boolean => {
  return useFeatureFlipper(FeatureFlag.canUpdatePerishability) ?? false;
};

export const useCanDiscontinueItem = (): boolean => {
  return useFeatureFlipper(FeatureFlag.DiscontinueItem) ?? true;
};

export const useHasBrandedItems = (): boolean => {
  return useFeatureFlipper(FeatureFlag.BrandedItems) ?? true;
};

export const useRichTextEditorForItemDescription = (): boolean => {
  return (
    useFeatureFlipper(FeatureFlag.RichTextEditorForItemDescription) ?? true
  );
};

export const hasCustomLocationItemsEnabled = (): boolean => {
  return useFeatureFlipper(FeatureFlag.CustomLocationItems) ?? true;
};

export const useCanEditItemTags = (): boolean => {
  return useFeatureFlipper(FeatureFlag.EditableItemTags) ?? true;
};
